var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Row, Col, InputGroup } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import "./main.css";
var MultiplePage = /** @class */ (function (_super) {
    __extends(MultiplePage, _super);
    function MultiplePage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            checkedInvestors: _this.props.location.state.investors
        };
        return _this;
    }
    MultiplePage.prototype.componentDidMount = function () {
        if (!this.props.location.state) {
            return this.props.history.push("/");
        }
    };
    MultiplePage.prototype.addInvestor = function (checked, investor) {
        var investors = this.state.checkedInvestors;
        if (checked) {
            investors.push(investor);
        }
        else {
            investors = investors.filter(function (inv) { return inv.name !== investor.name; });
        }
        this.setState({ checkedInvestors: investors });
    };
    MultiplePage.prototype.isChecked = function (investor) {
        return this.state.checkedInvestors.some(function (inv) { return inv.name === investor.name; });
    };
    MultiplePage.prototype.render = function () {
        var _this = this;
        if (!this.props.location.state) {
            // guard to stop rendering whilst we check above
            return null;
        }
        return (_jsxs(Row, __assign({ className: "g-0 vh-100" }, { children: [_jsx(Col, { md: 6, id: "hero" }, void 0), _jsxs(Col, __assign({ className: "vh-100 d-flex flex-column justify-content-between" }, { children: [_jsx("div", __assign({ className: "p-3 pe-4 d-flex flex-row-reverse" }, { children: _jsx("a", __assign({ className: "", href: "https://centuria.co.nz" }, { children: _jsx("img", { src: require("../../assets/img/CNI logo black.png"), alt: "Centuria logo", height: "24" }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "d-flex align-items-center flex-column w-75 mw-576 mx-auto" }, { children: _jsxs("div", { children: [_jsx("h1", __assign({ className: "mb-4 display-6" }, { children: "Sign in to view the meeting" }), void 0), _jsx("p", { children: "We see that you are the primary investor contact for multiple entities. These entities are listed below. Please confirm that you have the authority to vote on behalf of all these entities." }, void 0), _jsxs("div", __assign({ className: "" }, { children: [this.props.location.state.investors.map(function (investor, key) { return (_jsxs(InputGroup, __assign({ className: "mb-3" }, { children: [_jsx(InputGroup.Checkbox, { onChange: function (e) { return _this.addInvestor(e.target.checked, investor); }, checked: _this.isChecked(investor) }, void 0), _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h6", __assign({ className: "mb-0" }, { children: investor.name }), void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("p", __assign({ className: "card-text" }, { children: ["Units held in ", _jsx("span", { children: investor.holding_name }, void 0), ": ", _jsxs("strong", { children: [_jsx("span", { children: investor.holdings }, void 0), " units"] }, void 0)] }), void 0) }), void 0)] }), void 0)] }), key)); }), _jsx(Link, __assign({ to: {
                                                    pathname: this.props.location.state.votingOnly ? "/vote" : "/home",
                                                    state: __assign(__assign({}, this.props.location.state), { investors: this.state.checkedInvestors })
                                                }, className: "btn btn-dark w-100 " + (this.state.checkedInvestors.length === 0 ? "disabled-link" : "") }, { children: "Confirm" }), void 0)] }), void 0)] }, void 0) }), void 0), _jsx("div", { children: _jsx("p", __assign({ className: "text-center pb-2 m-0" }, { children: _jsxs("small", { children: ["\u00A9 Centuria Capital Limited", _jsx("span", __assign({ id: 'abn' }, { children: " ABN 22 095 454 336." }), void 0)] }, void 0) }), void 0) }, void 0)] }), void 0)] }), void 0));
    };
    return MultiplePage;
}(React.Component));
export default withRouter(MultiplePage);
