var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/login";
import Home from "./pages/home";
import Multiple from "./pages/multiple";
import VoteOnlyPage from "./pages/vote";
import "./main.css";
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import "whatwg-fetch";
ReactDOM.render(_jsx(Router, { children: _jsxs(Switch, { children: [_jsx(Route, __assign({ path: "/", exact: true }, { children: _jsx(Login, {}, void 0) }), void 0), _jsx(Route, __assign({ path: "/home" }, { children: _jsx(Home, {}, void 0) }), void 0), _jsx(Route, __assign({ path: "/vote" }, { children: _jsx(VoteOnlyPage, {}, void 0) }), void 0), _jsx(Route, __assign({ path: "/multiple" }, { children: _jsx(Multiple, {}, void 0) }), void 0), _jsx(Redirect, { to: "/" }, void 0)] }, void 0) }, void 0), document.getElementById("root"));
