var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from "react-router";
import "./main.css";
var LoginPage = /** @class */ (function (_super) {
    __extends(LoginPage, _super);
    function LoginPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            notFound: false,
            email: "",
            postCode: ""
        };
        return _this;
    }
    Object.defineProperty(LoginPage.prototype, "query", {
        get: function () {
            return new URLSearchParams(this.props.location.search);
        },
        enumerable: false,
        configurable: true
    });
    LoginPage.prototype.submit = function (event) {
        var _this = this;
        var id = this.query.get("meetingId");
        fetch("/api/investors", {
            method: "POST",
            body: JSON.stringify({
                email: this.state.email,
                postCode: this.state.postCode,
                uuid: id
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(function (res) { return res.json(); })
            .then(function (res) {
            if (res.length === 1) {
                _this.props.history.push({
                    pathname: res[0].voting_only == 0 ? "/home" : "/vote",
                    state: {
                        investors: res,
                        meetingId: id,
                        votingOnly: res[0].voting_only == 1 ? true : false
                    }
                });
            }
            else if (res.length > 1) {
                _this.props.history.push({
                    pathname: "/multiple",
                    state: {
                        investors: res,
                        meetingId: id,
                        votingOnly: res[0].voting_only == 1 ? true : false
                    }
                });
            }
            else {
                // didn't find anything probably 
                _this.setState({ notFound: true });
            }
        });
        // stop browser default stuff
        event.preventDefault();
        return false;
    };
    LoginPage.prototype.render = function () {
        var _this = this;
        return (_jsxs(Row, __assign({ className: "g-0 vh-100" }, { children: [_jsx(Col, { md: 6, id: "hero" }, void 0), _jsxs(Col, __assign({ className: "g-0 vh-100 d-flex flex-column justify-content-between" }, { children: [_jsx("div", __assign({ className: "p-3 pe-4 d-flex flex-row-reverse" }, { children: _jsx("a", __assign({ className: "", href: "https://centuria.co.nz" }, { children: _jsx("img", { src: require("../../assets/img/CNI logo black.png"), alt: "Centuria logo", height: "24" }, void 0) }), void 0) }), void 0), _jsxs("div", __assign({ className: "w-75 mw-576 mx-auto" }, { children: [_jsx("h1", __assign({ className: "mb-4 display-6" }, { children: "Sign in" }), void 0), _jsxs(Form, __assign({ onSubmit: function (e) { return _this.submit(e); } }, { children: [_jsxs(Form.Group, __assign({ className: "mb-3", controlId: "formBasicEmail" }, { children: [_jsx(Form.Label, { children: "Email Address" }, void 0), _jsx(Form.Control, { type: "email", placeholder: "Enter email", onChange: function (e) { return _this.setState({ email: e.target.value }); } }, void 0)] }), void 0), _jsxs(Form.Group, __assign({ className: "mb-3", controlId: "formBasicPostCode" }, { children: [_jsx(Form.Label, { children: "Post Code" }, void 0), _jsx(Form.Control, { type: "text", placeholder: "Post Code", onChange: function (e) { return _this.setState({ postCode: e.target.value }); } }, void 0), _jsx(Form.Text, __assign({ muted: true }, { children: "Note the above is based on the latest information we have received from you. If you have since moved, please use your previous post code." }), void 0)] }), void 0), _jsx(Button, __assign({ variant: "dark", type: "submit", className: "w-100" }, { children: "Next" }), void 0)] }), void 0), this.state.notFound ?
                                    _jsx(Alert, __assign({ variant: "danger" }, { children: "Not found" }), void 0) : null] }), void 0), _jsx("div", { children: _jsx("p", __assign({ className: "text-center pb-2 m-0" }, { children: _jsxs("small", { children: ["\u00A9 Centuria Capital Limited", _jsx("span", __assign({ id: 'abn' }, { children: " ABN 22 095 454 336." }), void 0)] }, void 0) }), void 0) }, void 0)] }), void 0)] }), void 0));
    };
    return LoginPage;
}(React.Component));
export default withRouter(LoginPage);
